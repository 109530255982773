var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-col',{staticClass:"hp-profile-menu py-24 px-0",staticStyle:{"flex":"0 0 240px"}},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"hp-profile-menu-header mt-16 mt-lg-0 text-center"},[_c('div',{staticClass:"hp-menu-header-btn mb-12 text-right"},[_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","toggle-class":"p-0"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-inline-block"},[_c('b-button',{staticClass:"btn btn-icon-only",attrs:{"variant":"text"}},[_c('i',{staticClass:"ri-more-2-line text-black-100 hp-text-color-dark-30 lh-1",staticStyle:{"font-size":"24px"}})])],1)]},proxy:true}])},[_c('b-dropdown-item',{on:{"click":_vm.changeImg}},[_vm._v("Cambiar imagen de perfil")])],1)],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"d-inline-block position-relative"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center rounded-circle",staticStyle:{"width":"80px","height":"80px"}},[_c('img',{attrs:{"src":_vm.data.avatar}})])])]),_c('h3',{staticClass:"mt-24 mb-4"},[_vm._v(_vm._s(_vm.data.fullName))]),_c('a',{staticClass:"hp-p1-body",attrs:{"href":'mailto:'+_vm.data.email}},[_vm._v(" "+_vm._s(_vm.data.email ?? '')+" ")])])]),_c('div',{staticClass:"hp-profile-menu-body w-100 text-left mt-48 mt-lg-0"},[_c('ul',[_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
            _vm.path == '/user/profile/personal-information' ? ' active' : ''
          }`,attrs:{"to":"/user/profile/personal-information"}},[_c('i',{staticClass:"iconly-Curved-User mr-8"}),_c('span',[_vm._v("Información personal")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1),_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
            _vm.path == '/user/profile/notifications' ? ' active' : ''
          }`,attrs:{"to":"/user/profile/notifications"}},[_c('i',{staticClass:"iconly-Curved-Notification mr-8"}),_c('span',[_vm._v("Notificaciones")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1),_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
            _vm.path == '/user/profile/password-change' ? ' active' : ''
          }`,attrs:{"to":"/user/profile/password-change"}},[_c('i',{staticClass:"iconly-Curved-Password mr-8"}),_c('span',[_vm._v("Cambiar contraseña")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1)])]),_c('b-modal',{attrs:{"size":"xl","id":"modal-add-img-profile","cancel-variant":"text","body-class":"py-50","header-class":"align-items-center flex-wrap pt-16 pb-0 px-0","centered":"","hide-footer":"","content-class":"modal-add-img-profile","modal-class":"hp-profile-modal-1"},scopedSlots:_vm._u([{key:"modal-header",fn:function({ close }){return [_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-between mb-16 px-24"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Cambiar imagen de perfil")]),_c('b-button',{staticClass:"btn-close bg-transparent p-0 d-flex align-items-center justify-content-center",staticStyle:{"min-height":"24px"},attrs:{"variant":"text"},on:{"click":function($event){return close()}}},[_c('i',{staticClass:"ri-close-line hp-text-color-dark-0 lh-1",staticStyle:{"font-size":"24px"}})])],1),_c('div',{staticClass:"divider my-0 p-0"})]}}])},[_c('form',{attrs:{"inline":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Imagen de perfil","for":""}},[_c('b-form-file',{ref:"file-input",attrs:{"placeholder":"Seleccione una imagen","accept":".jpg, .png, .gif, .pdf"},on:{"change":_vm.uploadFile}})],1)],1)],1)],1)]),_c('div',{staticClass:"hp-profile-menu-footer"},[_c('img',{attrs:{"src":require('@/assets/img/pages/profile/menu-img.svg'),"alt":"Profile Image"}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }