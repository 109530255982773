<template>
  <b-row>
    <b-col cols="12" md="8">
      <h2>información personal</h2>
      <p class="hp-p1-body mb-0">
        
      </p>
    </b-col>  

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <!-- <b-col cols="12" md="8">
      <h3>About</h3>
      <p class="hp-p1-body mb-0">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
        sodales sit amet nunc et vehicula. Mauris sed lectus nisi. Suspendisse
        velit mi, pretium non euismod vitae Suspendisse velit mi, pretium non
        euismod vitae
      </p>
    </b-col> -->

    <!-- <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div> -->

    <b-col cols="12">
      <b-row align-v="center" align-h="between">
        <b-col cols="12" md="6">
          <h3>contacto</h3>
        </b-col>

        <b-col cols="12" md="6" class="hp-profile-action-btn text-right">
          <b-button
            variant="primary"
            class="btn-ghost"
            v-b-modal.modal-edit-contact
          >
            Editar
          </b-button>
        </b-col>

        <b-col cols="12" class="hp-profile-content-list mt-8 pb-0 pb-sm-120">
          <ul>
            <li>
              <span class="hp-p1-body">Nombre completo</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
              {{ data.fullName }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">RUT</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
              {{ data.dni }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Email</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
              {{ data.email }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Teléfono</span>
              <a
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
                :href="'tel:'+data.phone"
              >
              {{ data.phone }}
              </a>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Fecha de nacimiento</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
              {{ data.birth }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Dirección</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
               {{ data.address }}
              </span>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <!-- <b-col cols="12">
      <b-row align-v="center" align-h="between">
        <b-col cols="12" md="6">
          <h3>Preferance</h3>
        </b-col>

        <b-col cols="12" md="6" class="hp-profile-action-btn text-right">
          <b-button
            variant="primary"
            class="btn-ghost"
            v-b-modal.modal-edit-Preferance
          >
            Edit
          </b-button>
        </b-col>

        <b-col cols="12" class="hp-profile-content-list mt-8">
          <ul>
            <li class="mt-18">
              <span class="hp-p1-body">Language</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                English
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Date Format</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                YYY.d.M
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Timezone</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                Cairo (GMT+3)
              </span>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-col> -->

    <b-modal
      id="modal-edit-contact"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Editar usuario</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form>
        <b-row>

          <b-col cols="12">
            <b-form-group label="Primer nombre">
              <b-form-input v-model="data.name" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Segundo nombre">
              <b-form-input v-model="data.name2" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Primer apellido">
              <b-form-input v-model="data.last_name" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Segundo apellido">
              <b-form-input v-model="data.last_name2" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Email">
              <b-form-input v-model="data.email" type="email" ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Teléfono">
              <imask-input 
                class="form-control"
                :value="data.phone"
                v-imask="phoneMask"
                :unmask="true"
                @complete="onCompletePhone"
                placeholder='+56 000 000 000'
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
              <b-form-group label="Tipo de Documento">
                <b-form-select v-model="data.dni_type_uuid " :options="DniType"></b-form-select>
              </b-form-group>
          </b-col>

          <b-col cols="12" v-if="data.dni_type_uuid == 4 || data.dni_type_uuid == 3">
            <b-form-group label="RUT">
              <imask-input 
                class="form-control"
                :value="data.dni"
                v-imask="dniMask"
                :unmask="true"
                @complete="onCompleteDni"
                placeholder='00.000.000-00'
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" v-if="data.dni_type_uuid != 4 && data.dni_type_uuid != 3">
            <b-form-group label="Documento">
              <b-form-input v-model="data.dni" type="text" ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Fecha de nacimiento">
              <imask-input 
                class="form-control"
                :value="data.birth"
                v-imask="maskDob"
                :unmask="true"
                @complete="onCompleteDob"
                placeholder='día/mes/año'
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8 mb-8">
            <b-form-group label="Address">
              <b-form-textarea v-model="data.address"></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button 
              variant="primary"
              block
              @click="profileEdit"
            >
              Editar
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-edit-contact')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <b-modal
      id="modal-edit-Preferance"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="hp-profile-modal-2"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Preferance Edit</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Language">
              <b-form-input type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button
              variant="primary"
              block
              @click="$bvModal.hide('modal-edit-Preferance')"
            >
              Edit
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-edit-Preferance')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
} from "bootstrap-vue";
 
import users from '@/logic/functions/users';
import validate_dni from '@/logic/functions/users/validate_dni';
import dni from '@/logic/functions/dni';
import sweet from '@/logic/functions/sweetAlert';
import { IMaskComponent, IMaskDirective } from 'vue-imask';
import { DateTime } from 'luxon'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    'imask-input': IMaskComponent,
 
  },
  directives: {
      imask: IMaskDirective
  },
  data() {
    return {
      data: this.user_info,
      dniIsValid : false,
      DniType:[],
      maskDob: {
        mask: '00/00/0000',
        lazy: false
      },
      dniMask : {
        mask: '00.000.000-{[*]}',
        lazy: false
      },
      phoneMask: {
        mask: '{+56} 000 000 000',
        lazy: false
      },
    };
  },
  props: {
    user_info:{
      type: Object,
    },
  },
  watch: {
    user_info(){
    this.data = this.user_info
    this.data.dni_type_uuid = 3;
    this.data.birth = DateTime.fromISO(this.data.birth).toFormat('dd/MM/yyyy');
    }
  },
  methods: {
    onCompletePhone(e){
      this.data.phone =  e.detail._value
    },
    onCompleteDni(e){
      let dni = e.detail._value.replace(/\./g,'');
        if(validate_dni.validaRut(dni)){
          this.dniIsValid = true;
          this.data.dni =  e.detail._value
          e.target.style.border = "none";
        }else{
          this.dniIsValid = false;
          e.target.style.border = "3px solid #FF0000";
        }
    },
    onCompleteDob(e){
      this.data.birth =  e.detail._value
    },
    async profileEdit(){

      let $modal = this.$bvModal;

      this.data.dni_type_uuid = String(this.data.dni_type_uuid);
 
      const realData = {
        "active": true,
        "address": this.data.address,
        // "avatar": this.data.avatar,
        "birth":  this.data.birth,
        "dni":  this.data.dni,
        "dni_type_uuid": this.data.dni_type_uuid,
        "email":  this.data.email,
        "firebase_token": this.data.firebase_token,
        "gender": this.data.gender,
        "last_name": this.data.last_name,
        "last_name2": this.data.last_name2,
        "name": this.data.name,
        "name2": this.data.name2,
        "nationality_id": this.data.nationality_id,
        "phone": this.data.phone
      };

      users.updateUserProfile(realData).then(function (response) {
        if(response.status == 200){
          sweet.ToastMsg('','success','Actualizacion completada');
          $modal.hide('modal-edit-contact');
        }
      })
      .catch(function (error) {
        sweet.ToastMsg('','error','Datos erroneos al actualizar');
      });

    },
    listDniType(){
      dni.list().then((response) => {
        Object.entries(response.data.data).forEach((entry) => {
          const [key, value] = entry;
          this.DniType.push( { value: value.uuid, text: value.name } );
        });
      }).catch((error) => {
 
      });
    },
  },
  mounted(){
    this.listDniType();
  },
};
</script>
