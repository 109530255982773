<template>
  <b-col class="hp-profile-menu py-24 px-0" style="flex: 0 0 240px">
    <div class="w-100">
      <div class="hp-profile-menu-header mt-16 mt-lg-0 text-center">
        <div class="hp-menu-header-btn mb-12 text-right">
          <b-dropdown variant="link" no-caret toggle-class="p-0">
            <template #button-content>
              <div class="d-inline-block">
                <b-button variant="text" class="btn btn-icon-only">
                  <i
                    class="ri-more-2-line text-black-100 hp-text-color-dark-30 lh-1"
                    style="font-size: 24px"
                  ></i>
                </b-button>
              </div>
            </template>
            <b-dropdown-item @click="changeImg">Cambiar imagen de perfil</b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="d-flex justify-content-center">
          <div class="d-inline-block position-relative">
            <div
              class="  d-flex align-items-center justify-content-center rounded-circle"
              style="width: 80px; height: 80px"
            >
              <img v-bind:src="data.avatar" />
            </div>

            <!-- <b-badge
              pill
              class="position-absolute bg-primary text-white border-0"
            >
              12
            </b-badge> -->
          </div>
        </div>

        <h3 class="mt-24 mb-4">{{ data.fullName }}</h3>
        <a :href="'mailto:'+data.email" class="hp-p1-body">
          {{ data.email ?? '' }}
        </a>
      </div>
    </div>

    <div class="hp-profile-menu-body w-100 text-left mt-48 mt-lg-0">
      <ul>
        <li class="mt-4 mb-16">
          <b-link
            to="/user/profile/personal-information"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              path == '/user/profile/personal-information' ? ' active' : ''
            }`"
          >
            <i class="iconly-Curved-User mr-8"></i>
            <span>Información personal</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>

        <li class="mt-4 mb-16">
          <b-link
            to="/user/profile/notifications"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              path == '/user/profile/notifications' ? ' active' : ''
            }`"
          >
            <i class="iconly-Curved-Notification mr-8"></i>
            <span>Notificaciones</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>

        <li class="mt-4 mb-16">
          <b-link
            to="/user/profile/password-change"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              path == '/user/profile/password-change' ? ' active' : ''
            }`"
          >
            <i class="iconly-Curved-Password mr-8"></i>
            <span>Cambiar contraseña</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>

 
      </ul>
    </div>

    <!-- update -->
    <b-modal
      size="xl"
      id="modal-add-img-profile"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-add-img-profile"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Cambiar imagen de perfil</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
 
          <b-col cols="12">
            <b-form-group label="Imagen de perfil" for="">
              <b-form-file  placeholder="Seleccione una imagen" @change="uploadFile" accept=".jpg, .png, .gif, .pdf" ref="file-input"></b-form-file>
            </b-form-group>
          </b-col>

          <!-- <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-add-img-profile')"
            >
              Cerrar
            </b-button>
          </b-col> -->
          
        </b-row>
      </form>
    </b-modal>

    <div class="hp-profile-menu-footer">
      <img
        :src="require('@/assets/img/pages/profile/menu-img.svg')"
        alt="Profile Image"
      />
    </div>
  </b-col>
</template>

<script>
import {
  BCol,
  BBadge,
  BButton,
  BLink,
  BDropdown,
  BDropdownItem,
  BCard,
    BTable,
    BRow,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect, // this
    BFormSelectOption,
    BFormDatepicker,
    BCalendar, //this
    BFormFile,
} from "bootstrap-vue";

  import sweet from '@/logic/functions/sweetAlert';
  import documents from '@/logic/functions/documents';
  import users from '@/logic/functions/users';
  import EventBus from '@/logic/functions/EventBus';
  
export default {
  data() {
    return {
      path: "",
      data: this.user_info
    };
  },
  props: {
    user_info:{
      type: Object,
    },
  },
  components: {
    BCol,
    BBadge,
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
    BCard,
      BTable,
      BRow,
      BFormCheckbox,
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BInputGroup,
      BInputGroupAppend,
      BFormSelect, // this
      BFormSelectOption,
      BFormDatepicker,
      BCalendar, //this
      BFormFile,
    
  },
  watch: {
    $route() {
      this.getRoute();
    },
    user_info(){
    this.data = this.user_info
 
    }
  },
  methods: {
    getRoute() {
      this.path = this.$route.path;
    },
    changeImg(){
      this.$bvModal.show('modal-add-img-profile')
    },
    uploadFile(event){
      sweet.ToastMsg('','success','Subiendo imagen ...');

      const files = event.target.files[0];
      const params =  '?comment='+files.name;
      const formData = new FormData();
      formData.append('file', files);

        users.upload_profile_picture( formData,params ).then((response) => {
          this.user_info.avatar = response.data.avatar
          EventBus.$emit('PROFILE_AVATAR', response.data.avatar);
          this.$bvModal.hide('modal-add-img-profile')
        }).catch((error) => {
            console.log(error)
          sweet.ToastMsg('','error','El archivo no se pudo subir');
        });

    },
  },
  profileDataReactive(){
      return this.data;
  },
  created() {
    this.getRoute();
  },
 
};
</script>
