<template>
  <b-row>
    <b-col cols="12">
      <h2>Cambiar contraseña</h2>
      <p class="hp-p1-body mb-0">
        Establezca una contraseña única para proteger su cuenta.
      </p>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12">
      <b-row>
        <b-col cols="12" sm="8" md="7" xl="5">
          <b-form>

            <b-form-group
              label="Nueva contraseña:"
              label-for="newPassword"
              class="mb-24"
            >
              <b-form-input
              v-model="user.newPassword"
                id="newPassword"
                type="text"
                placeholder="contraseña"
              ></b-form-input>
            </b-form-group>


            <b-form-group
              label="Repita la contraseña:"
              label-for="repeatPassword"
              class="mb-24"
            >
              <b-form-input
              v-model="user.repeatPassword"
                id="repeatPassword"
                type="text"
                placeholder="repita su contraseña"
              ></b-form-input>
            </b-form-group>

            <b-button @click="update_password" variant="primary" block> Cambiar contraseña </b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";

import sweet from '@/logic/functions/sweetAlert';
import users from '@/logic/functions/users';

export default {
  data: () => {
    return {
       user: {
        newPassword : "",
        repeatPassword: ""
       }
    };
  },
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
  },
  methods: {
    async update_password(){
      
      if(this.user.newPassword == this.user.repeatPassword){

        let update = await users.updatePassword({
          'password': this.user.newPassword
        })
        .then((response) => {
          this.user.newPassword = '';
          this.user.repeatPassword = '';
          sweet.ToastMsg('','success','contraseña actualizada');
        })
        .catch((error) => {
          sweet.ToastMsg('','error','contraseña no se a podido actualizar');
        });

      }else{
       sweet.ToastMsg('','error','las contraseña no coinciden');
      }

    }
  }
};
</script>
